<template>

    <div>
      <template>
        <div id="breadcrumb-alignment" class="mb-1">
          <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb
              :items="breadCrumb()"
            />
          </div>
        </div>
      </template>
      <!-- Table Container Card -->
  
      <div class="mt-2">
        
        <b-row class="mt-2" v-if="checkPermission('qm-qi-monthly-average', 'View')">

          <!-- filter section starts -->
          <b-col md="6">
  
            <b-card no-body class="custom-card-height-375">
            
              <b-card-body class="pb-0">
                
                    <b-row >
                      <b-col cols="12" md="12" class="mb-2">                      
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="sites"
                          label="site_name"
                          class="w-100"
                          placeholder="Project Site"
                          @input="resetData"
                          v-model="siteData"
                          :clearable="false"
                        />
                      </b-col>
                    
                      <b-col cols="12" md="12" class="mb-2">
                        <v-select
                            v-model="locations"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="name"
                            :options="alllocations"
                            placeholder="Select Locations"
                            @input="filterTable"
                            :close-on-select=false
                            :clear-on-select=false
                          />
                      </b-col>
                      
                      <b-col cols="12" md="12" class="mb-2">
                        <!-- <b-form-select v-model="status" @change="filterTable">
                          <b-form-select-option value="" disabled>Status</b-form-select-option>
                          <b-form-select-option value="All" >All</b-form-select-option>
                          <b-form-select-option value="Validate" >Validate</b-form-select-option>
                          <b-form-select-option value="Completed" >Completed</b-form-select-option>
                        </b-form-select> -->
                        <v-select
                            v-model="status"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="['Validate', 'Completed']"
                            placeholder="Select Status"
                            :clearable="true"
                            @input="filterTable"
                        />
                      </b-col>

                      <b-col cols="12" md="12" class="mb-2">
                        <b-form-datepicker
                          v-model="start"
                          :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                          locale="en" @input="updateStart()"
                        />
                      </b-col>

                      <b-col cols="12" md="12" class="mb-2">
                        <b-form-datepicker 
                          v-model="end"
                          :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                          locale="en" @input="filterTable" :min="disabledDates()"
                        />
                      </b-col>

                      <!-- <b-col cols="12" md="12" class="mb-2">
                        <v-select
                        v-model="report_status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="name"
                        :options="['Pass', 'Fail']"
                        placeholder="Select Report Status"
                        @input="filterTable"
                        />
                      </b-col> -->

                      <b-col cols="12" md="6" class="mb-2">
                        <b-button variant="danger" class="mt-0" @click="resetall">
                          <span class="text-nowrap">Reset</span>
                        </b-button>
                      </b-col> 
  
                    </b-row>
                  
              </b-card-body>
            </b-card>
          </b-col>
  
          <b-col md="6">
            <b-card no-body class="custom-card-height-375">
              <b-card-body>
                
                <!-- apex chart -->
                <vue-apex-charts type="pie" height="280" class="mt-0 mb-1" :options="customersPie.chartOptions" :series="series" />

                <!-- list group -->
                <div class="pt-25">
                  <div
                    v-for="(data,index) in chartData.listData"
                    :key="index"
                    class="d-flex justify-content-between"
                    :class="index === Object.keys(chartData.listData).length - 1 ? '':'mb-1'"
                  >
                    <div class="series-info">
                      <feather-icon
                        :icon="data.icon"
                        size="16"
                        class="mr-50"
                        :class="data.iconColor"
                      />
                      <span class="font-weight-bolder">{{ data.text }}</span>
                    </div>
                    <span>{{ data.result }}</span>
                  </div>
                </div>
              </b-card-body>
            </b-card>
          </b-col>

          <!-- <b-col md="3">
            <b-card no-body class="custom-card-height-375">
              <b-card-body>
                <b-row>
                  <b-col
                    cols="12"
                    md="12"
                    class="mb-md-0 mb-2 margin_bottom_zero_mobile">
                    <h5 class="margin_bottom_zero_mobile">Report Summary</h5>
                  </b-col>
                </b-row>
                <b-row class="mt-2">
                  <b-col md="12">
                    <div class="pt-25">
                      <div
                        v-for="(data,index) in chartData.summaryData"
                        :key="index" class="d-flex justify-content-between"
                        :class="index === Object.keys(chartData.summaryData).length - 1 ? '':'mb-1'"
                      >
                        <div class="series-info">
                          <feather-icon :icon="data.icon" size="16" class="mr-50" :class="data.iconColor"/>
                          <span class="font-weight-bolder">{{ data.text }}</span>
                        </div>
                        <span>{{ data.result }}</span>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col> -->

          <b-col md="12">
            <b-card no-body class="mb-0">

                <div class="m-2">
                  <b-row>
                    <b-col cols="12" md="3" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                      <label>Show</label>
                      <v-select 
                        v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                        :clearable="false" class="per-page-selector d-inline-block mx-50" @input="filterTable" 
                      />
                      <label>entries</label>
                    </b-col>
                    <!-- Search -->
                    <b-col cols="12" md="9">
                      <div class="d-flex align-items-center justify-content-end mobile-view">
                        <b-form-input
                          class="d-inline-block  mobile-margin-bottom mobile-margin-input mr-1"
                          v-model="searchQuery" placeholder="Search..." @input="filterTable"
                        />

                        <b-button :disabled="(siteData != null && siteData._id != 'all-site') && (items.length > 0) ? false:true" variant="success " class="mobile-margin-bottom" @click="getConsolidatedPDF('pdf')" v-if="checkPermission('qm-qi-monthly-average', 'Consolidate Report PDF')">
                          <span class="text-nowrap">Consolidated Report PDF</span>
                        </b-button>
                        
                        <b-button :disabled="(siteData != null && siteData._id != 'all-site') && (items.length > 0) ? false:true" variant="warning" class="mobile-margin-bottom ml-1" @click="getConsolidatedPDF('excel')" v-if="checkPermission('qm-qi-monthly-average', 'Consolidate Report Excel')">
                          <span class="text-nowrap">Consolidated Report Excel</span>
                        </b-button>

                        <b-button variant="primary" class="ml-1" @click="zipDownload()" :disabled="selected && selected.length > 0 ? false:true" v-if="checkPermission('qm-qi-monthly-average', 'Zip Download')">
                            <span class="text-nowrap">Zip Download</span>
                        </b-button>
                        
                      </div>
                    </b-col>
                  </b-row>
                </div>

                <b-table sticky-header
                ref="refUserListTable"
                class="position-relative sTable reverse-padding"
                :items="dataList"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                >
                  <template #head(checkbox)="items">
                     <b-form-checkbox class="custom-control-warning" v-model="selectAll" @change="selectall"/>
                  </template>
                  <template #cell(checkbox)="items">
                      <b-form-checkbox v-model="selected" :value="items.item._id" class="custom-control-warning my-1" v-if="items.item.status != 'draft'" />
                  </template>
                  <template #cell(unique_id)="items">
                      <span class="wordBreak">
                        <div class="my-1" @click="items.toggleDetails">{{ items.item.unique_id | capitalize}}</div>
                      </span>
                  </template>

                  <template #cell(info)="items">             
                      <feather-icon
                        :icon="items.detailsShowing ? 'EyeIcon' : 'EyeOffIcon'"
                        @click="items.toggleDetails"
                      />
                  </template>

                  <template #cell(title)="items">
                      <span class="wordBreak">{{ items.item.title | capitalize}}</span>
                  </template>

                  <template #cell(site)="items">
                      <span class="wordBreak">{{ items.item.site.site_name | capitalize}}</span>
                  </template>

                  <template #cell(inspected_by)="items">
                      <span class="wordBreak">{{ items.item.inspected_by | capitalize}}</span>
                  </template>

                  <template #cell(location)="items">
                      <span class="wordBreak"> {{items.item.location.name | capitalize}}</span>
                  </template>

                  <template #cell(inspection_date_time)="items">
                      <span @click="items.toggleDetails">{{sitedateTime2(items.item.inspection_date_time,siteData.pdf_date_format,siteData.pdf_time_format)}}</span>
                  </template>

                  <!-- <template #cell(total_score)="items">
                      <span class="wordBreak">{{ items.item.total_score +' %' }}</span>
                  </template> -->

                  <template #cell(status)="items">    
                      <div @click="items.toggleDetails">
                          <div v-if="items.item.status == 'completed'">
                        
                            <b-badge pill variant="danger" class="text-capitalize" v-if="(items.item.reject_to != null)" >
                                <span>
                                    {{ 'Rejected - '+ items.item.reject_by | removeUnderCapitalize}}
                                </span>
                            </b-badge>

                            <b-badge pill variant="warning" class="text-capitalize" v-else-if="(items.item.level_one_user_signature == null && items.item.level_user_status != null)">
                                <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                                    {{ 'Pending' | removeUnderCapitalize}}
                                </span>
                                <span v-else>
                                    {{ 'Pending - '+ items.item.level_one_user_role | removeUnderCapitalize}}
                                </span>
                            </b-badge>
                            
                            <b-badge pill variant="warning" class="text-capitalize" v-else-if="(items.item.level_user_status != null) && (items.item.level_one_user_signature != null) && (items.item.level_two_user_signature == null)" >
                                <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                                    {{ 'Pending' | removeUnderCapitalize}}
                                </span>
                                <span v-else>
                                    {{ 'Pending - '+ items.item.level_two_user_role | removeUnderCapitalize}}
                                </span>
                            </b-badge>

                            <b-badge pill variant="warning" class="text-capitalize" v-else-if="(items.item.level_user_status != null) && (items.item.level_two_user_signature != null)" >
                                <span v-if="items.item.level_user_status.role == $store.getters.currentUser.role">
                                    {{ 'Pending' | removeUnderCapitalize}}
                                </span>
                                <span v-else>
                                    {{ 'Pending - '+ items.item.level_three_user_role | removeUnderCapitalize}}
                                </span>
                            </b-badge>

                            <b-badge pill variant="success" class="text-capitalize" v-else >
                            {{ 'Completed'}}
                            </b-badge>
                          </div>
                          <div v-else>

                            <b-badge pill variant="secondary" class="text-capitalize" >
                              {{ 'Draft' }}
                            </b-badge>

                          </div>
                      </div>
                  </template>

                  <template #cell(actions)="items">
                    <b-link v-b-tooltip.hover.v-warning title="Download" variant="outline-warning" @click="download(items.item.pdf)" v-if="checkPermission('qm-qi-monthly-average', 'Download')">
                        <feather-icon icon="DownloadIcon" class="mr-1 mediumSize"/>
                    </b-link>
                    <b-link v-b-tooltip.hover.v-warning title="Delete" variant="outline-warning" @click="deleteItem(items.item._id)" v-if="checkPermission('qm-qi-monthly-average', 'Delete')">
                        <feather-icon icon="Trash2Icon" class="mr-1 mediumSize"/>
                    </b-link>
                  </template>

                  <template #row-details="items">
                      <div class="p-1 px-2 fbDetail mb-1">
                          <b-row>

                            <b-col cols="12" xl="12" >
                                <table class="mt-2 mt-xl-0 w-100">
                                    <tr class="mb-1">                                      

                                        <th class="pb-50" style="width: 16%">
                                          <div class="d-flex align-items-center">
                                        
                                            <div class="ml-1">
                                              <h6 class="mb-0 mediumFonts">
                                                Project Site 
                                              </h6>
                                            </div>
                                          </div>
                                        </th>
                                        <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                            {{ items.item.site.site_name | capitalize }}
                                        </td>

                                        <th class="pb-50" style="width: 16%">
                                          <div class="d-flex align-items-center">
                                      
                                            <div class="ml-1">
                                              <h6 class="mb-0 mediumFonts">
                                                Inspected By 
                                              </h6>
                                            </div>
                                          </div>
                                        </th>
                                        <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                            {{ items.item.inspected_by | capitalize }}
                                        </td>

                                        <th class="pb-50" style="width: 16%">
                                          <div class="d-flex align-items-center">
                                      
                                            <div class="ml-1">
                                              <h6 class="mb-0 mediumFonts">
                                                Approver Remarks 
                                              </h6>
                                            </div>
                                          </div>
                                        </th>
                                        <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                          <span v-b-tooltip.hover.v-primary.top :title="items.item.level_one_user.role + ' - ' + items.item.level_one_user_remarks | removeUnderCapitalize"
                                            class="wordBreak" v-if="(items.item.level_one_user_remarks != null && items.item.level_one_user_remarks != '') && items.item.level_one_user_remarks.length > 50">
                                            {{ items.item.level_one_user.role | removeUnderCapitalize }} - {{ items.item.level_one_user_remarks | capitalize | truncate }}</span>
                                          <span class="wordBreak"
                                              v-else-if="(items.item.level_one_user_remarks != null && items.item.level_one_user_remarks != '') && items.item.level_one_user_remarks.length <= 50">{{ items.item.level_one_user.role
                                                | removeUnderCapitalize }} - {{ items.item.level_one_user_remarks | capitalize | truncate }}</span>
                                          <span class="wordBreak" v-else>{{ 'N/A' }}</span>
                                        </td>
                                      </tr>

                                      <tr class="mb-1">
                                          <th class="pb-50" style="width: 16%">
                                              <div class="d-flex align-items-center">
                                        
                                                <div class="ml-1">
                                                  <h6 class="mb-0 mediumFonts">
                                                    Reject Reason 
                                                  </h6>
                                                </div>
                                              </div>
                                          </th>
                                          <td class="pb-50 mediumFonts" style="width: 17%;padding-top: 8px;">
                                              <span v-if="items.item.reject_comment && items.item.reject_comment.length > 50" 
                                                v-b-tooltip.hover.v-primary.top :title="items.item.reject_comment" class="wordBreak">
                                                {{ items.item.reject_comment | capitalize | truncate }}</span>
                                              <span v-else class="wordBreak">{{ items.item.reject_comment | capitalize }}</span>
                                          </td>
                                    </tr>
                                  
                                </table>
                            </b-col>

                          </b-row>
                      </div>

                  </template>

                </b-table>

                <div class="mx-2 mb-2">
                  <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                      <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>

                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                      <b-pagination
                        v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number
                        class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @input="filterTable"
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18"/>
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18"/>
                        </template>
                      </b-pagination>
                    </b-col>
                  </b-row>
                </div>

              </b-card>
          </b-col>

        </b-row>        
      </div>  
  
    </div>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BAlert,BFormGroup,
    BBadge, BDropdown, BDropdownItem, BPagination,BFormCheckbox,BCardBody,BFormSelectOption,BFormSelect,VBTooltip,BFormTextarea,BForm, BFormDatepicker,BFormRadio,BBreadcrumb
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { GET_API,POST_API } from "../../../store/actions.type"
  import Datepicker from 'vuejs-datepicker';
  import moment from "moment-timezone";
  import VueApexCharts from 'vue-apexcharts';
  import { $themeColors } from '@themeConfig';
  import flatPickr from 'vue-flatpickr-component';
  
  export default {
    components: {
      Datepicker, BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, 
      BPagination, BFormCheckbox, vSelect, BFormSelect, BFormSelectOption, VueApexCharts, BFormTextarea, BAlert, BFormGroup, BForm, BFormDatepicker,
      BFormRadio, BBreadcrumb, flatPickr
    },
    directives: {
      'b-tooltip': VBTooltip
    },
    data() {
      return {
        report_status: null,
          items:[],

          webUrl: process.env.VUE_APP_SERVER_URL,
          totalRecords : 0,
          siteData:null,
  
          sites:[],
          clearButton:true,
          clearButton2:true,
          start:moment().tz('Asia/Singapore').toDate(),
          end:moment().tz('Asia/Singapore').toDate(),
          locations:[],
          alllocations:[],
          allcrews:[],
          status:null,

          chartData: {
            listData:[],
            summaryData:[],
          },

          series:[],

          customersPie: {
          chartOptions: {
              chart: {
                toolbar: {
                  show: false,
                },
              },
              labels: ['Validate', 'Completed'],

              dataLabels: {
                enabled: false,
              },
              legend: { show: false },
              stroke: {
                width: 0,
              },
              colors: [$themeColors.warning, $themeColors.success],
            },
          },
  
        popshowDismissibleAlert:false,
        error_message:'',
  
        taskdate:moment.tz('Asia/Singapore').subtract(1,'days').format('YYYY-MM-DD'),
        taskgroup:[],
        
        date_range:moment.tz('Asia/Singapore').startOf('month').format('DD MMM YYYY') + ' to ' + moment.tz('Asia/Singapore').format('DD MMM YYYY'),
        date_config:{ 
            mode: 'range',
            dateFormat: 'd M Y',
            defaultDate: [
                moment.tz('Asia/Singapore').startOf('month').format('DD MMM YYYY'),
                moment.tz('Asia/Singapore').format('DD MMM YYYY')
            ]
        },

        assign_status:'all',

        // table vars 
        tableColumns: [
            { key: 'checkbox', label: '', sortable: false, thStyle: { width: '5%', fontSize:'11px' } },
            { key: 'info', label: 'INFO', sortable: false, thStyle: { width: '5%', fontSize:'11px' } },
            { key: 'unique_id', label: 'ID', sortable: true, thStyle: { width: '24%', fontSize:'11px' } },
            { key: 'location', label: 'Location', sortable: false, thStyle: { width: '20%', fontSize:'11px' } },
            // { key: 'total_score', label: 'Total Score', sortable: false, thStyle: { width: '12%', fontSize:'11px' } },
            { key: 'inspection_date_time', label: 'Inspection Date & Time', sortable: true, thStyle: { width: '22%', fontSize:'11px' } },
            { key: 'status', label: 'Status', sortable: false, thClass: 'alignCenter', tdClass: 'alignCenter', thStyle: { width: '12%', fontSize:'11px' } },
            { key: 'actions', label: 'Action', sortable: false, thStyle: { width: '12%', fontSize:'11px' } },
        ],
        searchQuery    : '',
        isSortDirDesc  : true,
        currentPage    : 1,
        sortBy         : 'order',
        perPageOptions : [10, 20, 50, 100],
        perPage        : 10,
        from           : null,
        to             : null, 

        selectAll:false,
        selected:[],
      }
    },
    methods : {
      resetall(){

        this.report_status= null;
        this.locations = [];
        this.alllocations = [];
        this.status = null;
        
        this.setDate()

        this.allSites()

        this.allLocations();
        this.filterTable();
      },
      
        getConsolidatedPDF(type){
            var keyword = this.searchQuery;
            var sortBy = this.sortBy;
            var sortDirection = this.isSortDirDesc;
            var site = this.siteData ? this.siteData._id : "";
            var site_name = this.siteData ? this.siteData.site_name : "";
            
            var locations  =  [];

            for (var i = 0; i < this.locations.length; i++) {
                locations.push(this.locations[i]._id);
            }

            var om_sites = [];

            for (var i = 0; i < this.$store.getters.currentUser.om_sites.length; i++) {
                om_sites.push(this.$store.getters.currentUser.om_sites[i]);
            }

            var role = this.$store.getters.currentUser.role;

            // var crew =  this.crew;
            var start = this.start ? moment(this.start).format('YYYY-MM-DD') : "";
            var end = this.end ? moment(this.end).format('YYYY-MM-DD') : "";
            var status = this.status;
            var report_status = this.report_status == null ? 'null' : this.report_status;

            var params = "keyword="+keyword+"&sortBy="+sortBy+"&sortDirection="+sortDirection+"&site="+site+"&site_name="+site_name+
                "&locations="+locations+"&start="+start+"&end="+end+"&organization="+ this.$store.getters.currentUser.organization+
                "&role="+role+"&om_sites="+om_sites.join(',')+"&status="+status+"&report_status="+report_status;

            var baseurl = "";

            if (type == 'pdf') {
                baseurl = process.env.VUE_APP_SERVER_URL +"/api/qm-qa-monthly-average-pdf?"+params;
            }else{
                baseurl = process.env.VUE_APP_SERVER_URL +"/api/qm-qa-monthly-average-excel?"+params;
            }
            window.open(baseurl,'_blank');
        },

      selectall(e){
        this.selected = [];

        if (e) {
          var selected = [];
          this.items.forEach(function(item){
              if(item.status != 'draft'){
                selected.push(item._id);
              }
          })
          this.selected = selected;
        }
      },

      zipDownload(){
        var organization  = this.$store.getters.currentUser.organization;
        var id            = this.selected && this.selected.length > 0 ? this.selected.toString() : [];
        var start         = moment(this.start).format('YYYY-MM-DD');
        var end           = moment(this.end).format('YYYY-MM-DD');
        var site          = this.siteData ? this.siteData.site_name : '';

        var params  = "organization="+organization+"&id="+id+"&start="+start+"&end="+end+"&site="+site;
        var baseurl = "";

        baseurl        = process.env.VUE_APP_SERVER_URL + '/api/qm-qa-zip-report?' + params;
        this.selectAll = false,
        this.selected  = []
        
        window.open(baseurl,'_blank');
      },

      exportPDF(){
        var tempLocationId   = this.locations.map(obj => obj._id);
        var tempLocationName = this.locations.map(obj => obj.name);

        var matchedID = this.allcrews.find((obj) => obj._id === this.crew);
        var crew_name = matchedID ? matchedID.full_name : 'null';

        let route = this.$router.resolve({ 
            name: 'export-feedback-request-analytics',
            params: {
                site          : this.siteData ? this.siteData._id : 'null',
                location      : tempLocationId.length > 0 ? tempLocationId.toString() : 'null',
                crew          : this.crew.length > 0 ? this.crew : 'null',
                status        : this.status.length > 0 ? this.status : 'null',
                start         : moment(this.start).format('YYYY-MM-DD'),
                end           : moment(this.end).format('YYYY-MM-DD'),
                site_name     : this.siteData ? this.siteData.site_name : 'null',
                location_name : tempLocationName.length > 0 ? tempLocationName.toString() : 'null',
                date_format   : this.siteData ? this.siteData.pdf_date_format : 'null',
                crew_name     : crew_name,
                assign_status : this.assign_status,
            }
        });
        window.open(route.href, '_blank');
      },

      dataList(){
        return this.$store.dispatch(POST_API, {
            data:{
              site       : this.siteData ? this.siteData._id : null,  
              locations  : this.locations,
            //   crew       : this.crew,
              start      : moment(this.start).format('YYYY-MM-DD'),
              end        : moment(this.end).format('YYYY-MM-DD'),
              status     : this.status,
              role       : this.$store.getters.currentUser.role,
              om_sites   : this.$store.getters.currentUser.om_sites,
            //   assign_status : this.assign_status,
            
              //  table vars 
              keyword       : this.searchQuery,
              page          : this.currentPage,
              rowsPerPage   : this.perPage,
              sortBy        : this.sortBy,
              sortDirection : this.isSortDirDesc,
              report_status : this.report_status
            },
            api: '/api/qm-qa-monthly-average'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.series =  this.$store.getters.getResults.data.series;
                this.chartData.listData = this.$store.getters.getResults.data.listData;
                this.chartData.summaryData = this.$store.getters.getResults.data.summaryData;

                // table vars
                this.items        = this.$store.getters.getResults.data.docs;
                this.totalRecords = this.$store.getters.getResults.data.total;
                this.perPage      = this.$store.getters.getResults.data.limit;
                this.from         = this.$store.getters.getResults.data.from
                this.to           = this.$store.getters.getResults.data.to

                return this.items;
            }
        });
      },

      variantColor(status){
        if (status == 'open') {
          return 'primary';
        }else if(status == 'in_progress'){
          return 'warning'
        }else if(status == 'validate'){
          return 'info'
        }else if(status == 'failed'){
          return 'danger'
        }else{
          return 'success';
        }
      },

      filterTable(){
        // this.dataList();
        this.$refs.refUserListTable.refresh();
        this.selectAll = false,
        this.selected  = []
      },
      
      allSites(){
        return this.$store.dispatch(POST_API, {
           data:{
              role:this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.sites = this.$store.getters.getResults.data;
                
                // var obj = {
                //   _id:'all-site',
                //   site_name:'All Project Sites',
                //   pdf_date_format: 'DD MMM YYYY',
                //   pdf_time_format: 'HH:mm'
                // }
                // this.sites.unshift(obj);
  
                this.siteData = this.defaultSite(this.sites);
                this.allLocations();
                this.filterTable();
  
                return this.sites;
            }
        });
      },

      customFormatter(date) {
        return moment(date).format('DD MMM YYYY');
      },

      resetData(){
        this.locations = [];
        this.crew = '';
        
        this.alllocations = [];

        this.allLocations();
        this.filterTable()
      },

      allLocations(){
        return this.$store.dispatch(POST_API, {
             data:{
               site:this.siteData ? this.siteData._id : null,
               type:'QI'
             },
             api: '/api/all-locations'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data  = this.$store.getters.getResults.data;
                  
                  this.alllocations = data;
  
                  if (this.$route.params.locations && this.$route.params.locations != 'null') {
            
                      var locations = this.$route.params.locations.split(',');

                      var selectedLocation = [];
                      
                      this.alllocations.forEach(function(item){

                          if (locations.indexOf(item._id) >= 0) {
                             selectedLocation.push(item);
                          }
                      })
                      this.locations = selectedLocation;
                  }
                  return this.alllocations;
              }
          });
      },

      updateStart(){
        this.end = moment(this.start).tz('Asia/Singapore').toDate();
        this.filterTable();
      },

      disabledDates(){
        if (this.start) {
          return moment(this.start).format('YYYY-MM-DD');
        }else{
          return moment().format('YYYY-MM-DD');  
        }
  
        
      },

      setDate(){
			    var date = this.date_range.split(' to ');
	        if (date[1]) {
	            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
	            this.end = moment(new Date(date[1])).format('YYYY-MM-DD');
	        }else{
	            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
	            this.end = moment(new Date(date[0])).format('YYYY-MM-DD');
	        }
          this.filterTable();
		},

        deleteItem(id){
      var msg = 'Are you sure want to delete this record?';
      var message='Report Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                          status:'deleted'
                        },
                    api:"/api/change-qm-qa-report-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              
          })
      },

      download(pdf){
        window.open(pdf, '_blank');
      },
      
      breadCrumb(){
        var item = [{
          to:{name:'client-dashboard'},
          text: 'Dashboard',
        },{
          to:null,
          text: 'Quality Management',
        },{
          to:null,
          text: 'Report',
        },{
          to:null,
          text: 'Monthly Average',
          active:true
        }];
        return item;
      },
  
    },
    mounted(){
      this.allSites();
      this.setDate();
    }
    
  }
  </script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
/* @media only screen and (max-width: 768px) {
  .b-table tbody td:nth-child(6) {
    display: table-cell !important;
  }
} */
</style>